<template>
  <div class="checkbox_info_confirmed">
    <div class="checkbox_info_confirmed_content">
      <span class="cicc_i">i</span>
      <p class="cicc_p" v-html="message">{{message}}</p>
      <div class="cicc_button_content">
<!--        <button class="cicc_cancel" @click="isCancel">返回修改</button>-->
<!--        <button class="cicc_confirm" @click="isConfirm">确认提交</button>-->
        <button class="cicc_cancel" @click="clickCancel">返回修改</button>
        <button class="cicc_confirm" @click="clickConfirm">确认提交</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirm",
  props:{
    message:String
  },
  methods:{
    clickCancel(){
      this.$emit('cancel')
    },
    clickConfirm(){
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
//确认框
.checkbox_info_confirmed
  position fixed
  background-color rgba(0,0,0,0.6)
  top 0
  right 0
  bottom 0
  left 0
  display flex
  flex-direction column
  align-items center
  justify-content center
  .checkbox_info_confirmed_content
    width 600px
    height 400px
    background-color white
    border-radius 60px
    display flex
    flex-direction column
    align-items center
    justify-content center
    padding 10px 0
    .cicc_i
      width 80px
      height 80px
      border-radius 50%
      text-align center
      line-height 80px
      background $error
      color white
      font-size 50px
      font-weight bold
    .cicc_p
      font-size 36px
      margin 50px 30px
      text-align center
    .cicc_button_content
      button
        buttonSet()
        display inline-block
        width 240px
        margin 0 10px
        font-size 30px
      .cicc_cancel
        color #666666
        background #EDEDED
      .cicc_confirm
        color #FFF
</style>